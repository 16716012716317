import { I18n } from '@aws-amplify/core';
import { USER_CHOICE_LANGUAGE } from './consts'
import { strings as stringGlobal } from './globalLocalization'


I18n.putVocabularies(stringGlobal);
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE) ? localStorage.getItem(USER_CHOICE_LANGUAGE) : 'de'
I18n.setLanguage(lang)

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}