import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { strings as stringGlobal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { USER_CHOICE_LANGUAGE } from '../utils/consts'

I18n.putVocabularies(stringGlobal);
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
I18n.setLanguage(lang)

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);

  useEffect(() => {

    if (inviteMatches && orgMatches) {
      loginWithRedirect({
        organization: orgMatches[1],
        invitation: inviteMatches[1],
        appState: {
          returnTo: '/',
        },
      });
    }

  }, [inviteMatches,orgMatches,loginWithRedirect ]);

  return (
    <div className="flex w-full h-screen items-center justify-center flex-col">
        <img src="/assets/images/organizations/aufzughelden.png" alt="Aufzughelden" />
        <br/>
        <br/>
        {I18n.get('messageRedirect')}
    </div>
  );
};
