export const strings = {
    en: {
        logout: 'Log Out',
        login: 'login',
        backLabel: 'Back',
        search: 'Search',
        elevator: 'Elevator',
        door: 'Door',
        runs: 'Runs',
        openings: 'Openings',
        closings: 'Closings',
        blockages: 'Blockages',
        loadErrorInstallation: 'Loading of installation fails.',
        connectedToElevator: 'Connected to elevator',
        notConnectedToElevator: 'Lost elevator connection',
        noCloudConnection: 'Lost cloud connection',
        errorControl: 'Error control!',
        warningControl: 'Warning control',
        tries: 'Tries',
        duration: 'Duration',
        date: 'Date',
        Date: 'Date',
        time: 'Time',
        Time: 'Time',
        event: 'Event',
        messageLogout: 'You successfully logged out!',
        messageGoTo: 'Click here to ',
        messageRedirect: 'You will be redirected...',
        takeOver: 'Apply',
        last7days: 'Last 7 days',
        last14days: 'Last 14 days',
        last30days: 'Last 30 days',
        last60days: 'Last 60 days',
        today: 'Today',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        noDataForTimePeriod: 'No data available yet.',
        noData: 'No data available yet.',
        charLobbyFloor: 'G',
        total: 'Total',
        errorOccurred: 'An error has occurred!',
        auth0ErrorMessageNotPartOfOrg: 'The User(###userName###) is not part of the ###orgName### Organization.',
        State: 'State',
        state: 'State',
        level: 'level',
        Level: 'Level',
        supportWelcome: 'Welcome to the Aufzughelden customer dashboard',
        supportSubline: 'Customer service hotline',
        supportMailSubline: 'Customer Service Mail Support',
        tooManyCompareSelectedPre: 'You have already selected ',
        tooManyCompareSelectedPost: ' elevators to compare.',
    },
    de: {
        logout: 'Abmelden',
        login: 'Login',
        backLabel: 'Zurück',
        search: 'Suche',
        elevator: 'Aufzug',
        door: 'Tür',
        runs: 'Fahrten',
        openings: 'Öffnungen',
        closings: 'Schließungen',
        blockages: 'Blockierungen',
        loadErrorInstallation: 'Beim Laden der Installation ist ein Fehler aufgetreten.',
        connectedToElevator: 'Verbindung ist hergestellt',
        notConnectedToElevator: 'Verbindung zur Anlage verloren',
        noCloudConnection: 'Verbindung zur Cloud verloren',
        errorControl: 'Fehler Steuerung!',
        warningControl: 'Warhinweis Steuerung',
        tries: 'Versuche',
        duration: 'Dauer',
        date: 'Datum',
        Date: 'Datum',
        time: 'Uhrzeit',
        Time: 'Zeit',
        event: 'Event',
        messageLogout: 'Sie haben sich erfolgreich ausgeloggt!',
        messageGoTo: 'Hier gehts zum ',
        messageRedirect: 'Sie werden weitergeleitet...',
        takeOver: 'Übernehmen',
        last7days: 'Die letzen 7 Tage',
        last14days: 'Die letzen 14 Tage',
        last30days: 'Die letzen 30 Tage',
        last60days: 'Die letzen 60 Tage',
        today: 'Heute',
        thisMonth: 'Diesen Monat',
        lastMonth: 'Letzten Monat',
        noDataForTimePeriod: 'Bisher sind noch keine Daten vorhanden.',
        noData: 'Bisher sind noch keine Daten vorhanden.',
        charLobbyFloor: 'E',
        total: 'Gesamt',
        errorOccurred: 'Es ist ein fehler aufgetreten!',
        auth0ErrorMessageNotPartOfOrg: 'Der angemeldete Benutzer (###userName###) ist nicht Teil der ###orgName### Organisation.',
        state: 'status',
        State: 'Status',
        level: 'etage',
        Level: 'Etage',
        supportWelcome: 'Willkommen zum Aufzughelden Kundendashboard',
        supportSubline: 'Kundendienst Hotline',
        supportMailSubline: 'Kundendienst Mail-Support',
        tooManyCompareSelectedPre: 'Sie haben bereits ',
        tooManyCompareSelectedPost: ' Aufzüge zum Vergleich ausgewählt.',
    },
};