export const strings = {
    en: {
      myAccount: 'My account',
      aboutYou: 'About you',
      fullName: 'Full name',
      eMail: 'Email',
      organisations: 'Organization/s',
    },
    de: {
      myAccount: 'Mein Account',
      aboutYou: 'Ihre Daten',
      fullName: 'Name',
      eMail: 'E-Mail',
      organisations: 'Organisationen',
    },
  };