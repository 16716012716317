
export const strings = {
    en: {
      navDashboard: 'Dashboard',
      navInstallations: 'Installations',
      navDocuments: 'Documents',
      sprachwahl: 'Language selection',
    },
    de: {
      navDashboard: 'Dashboard',
      navInstallations: 'Anlagen',
      navDocuments: 'Dokumente',
      sprachwahl: 'Sprachwahl',
    },
  };