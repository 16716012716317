
import { UsersResponse } from '../interfaces/UsersResponse';
import { API_URL } from './consts';


export const getUserInfo = async (accessToken:string): Promise<UsersResponse> => {
  const response = await customFetchWithAuthHeader(`${API_URL}/api/v1/users/`, {
    method: 'GET',
  },accessToken);
  const data = await response.json();
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const customFetchWithAuthHeader = async (url: string, options: RequestInit,accessToken:string) => {

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };
  const response = await fetch(url, {
    ...options,
    headers,
  });
  return response;
};

