
export const strings = {
    en: {
      imprint: 'Legal notice',
      imprintLink: 'https://www.digitalspine.io/impressum/',
      dataProtection: 'Privacy',
      dataProtectionLink: 'https://www.digitalspine.io/datenschutz/',
      footerText: '© Digital Spine 2023. All rights reserved',
      contact :'Contact',
      followUs :'Follow us.',
      writeUs : 'Contact us',
    },
    de: {
      imprint: 'Impressum',
      imprintLink: 'https://www.digitalspine.io/impressum/',
      dataProtection: 'Datenschutz',
      dataProtectionLink: 'https://www.digitalspine.io/datenschutz/',
      footerText: '© Digital Spine 2023. Alle Rechte vorbehalten',
      contact :'Kontakt',
      followUs :'Folgen Sie uns.',
      writeUs : 'Schreiben Sie uns',
    },
  };