import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import Menu from './HeaderMenu'
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState, GlobalStateInterface } from '../../utils/globalStateProvider';
import { strings as stringHeader } from './HeaderLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { USER_CHOICE_LANGUAGE } from '../../utils/consts';
import { mergeDeepRight } from 'ramda';
import IconFolderOpenerDarkBlue from '../../assets/images/icon_folderOpener_dark_blue.svg'
import logoWhite from '../../assets/images/ds_logo_white.svg'
import { scrollTop } from '../../utils/helpers';

function Header(props:{isLoggedout?:boolean}) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { logout} = useAuth0()
  const { state, setState } = useGlobalState()// eslint-disable-line @typescript-eslint/no-unused-vars
  const [menuSupport, setMenuSupport] = useState(false)

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringHeader,stringGlobal));
    I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')
    }, []);  

    const logoutAuth0 = () => {
      let partsUrl = window.location.pathname.split('/')
      if(partsUrl[1] && partsUrl[1].indexOf('org_') === 0 ){
        logout({ returnTo: window.location.origin + '/logout?organization=' + partsUrl[1].split('_')[1] })
      }
      else{
        logout({ returnTo: window.location.origin + '/logout'})
      }
    }

    const handleLocalisation = (value:string) => {
      localStorage.setItem(USER_CHOICE_LANGUAGE, value);
      I18n.setLanguage(value)
  
      let dataGlobal:Partial<GlobalStateInterface> = {language:value}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }

  let choosenLanguage = 'Deutsch'
  if(localStorage.getItem(USER_CHOICE_LANGUAGE) === 'en'){
    choosenLanguage = 'English'
  }

  return (
    <section className="Header h-20 s:h-24  xxl:h-28 bg-blueLight sticky top-0  z-10 w-full">
      <div className="flex  items-center justify-between w-full m-0 pt-4" >
        <div >
          <Link to={'/welcome'} onClick={ ()=>{ scrollTop() }} aria-label="Aufzughelden"><img className=" h-8 s:h-10 m:h-12 xl:h-14 xxl:h-16" src={logoWhite} alt="Aufzughelden" /></Link>
        </div>
        <div >
            <div className="text-lg flex items-center">
              <div className="mr-2 m:hidden  cursor-pointer">
                <svg onClick={()=>setMenuOpen(!menuOpen)}  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.272461 1.45455C0.272461 0.651222 0.923683 0 1.72701 0H16.2725C17.0758 0 17.727 0.651222 17.727 1.45455C17.727 2.25787 17.0758 2.90909 16.2725 2.90909H1.72701C0.923685 2.90909 0.272461 2.25787 0.272461 1.45455ZM0.272483 8C0.272483 7.19668 0.923705 6.54545 1.72703 6.54545H16.2725C17.0758 6.54545 17.727 7.19668 17.727 8C17.727 8.80332 17.0758 9.45455 16.2725 9.45455H1.72703C0.923707 9.45455 0.272483 8.80332 0.272483 8ZM1.72703 13.0909C0.923705 13.0909 0.272483 13.7421 0.272483 14.5455C0.272483 15.3488 0.923707 16 1.72703 16H16.2725C17.0758 16 17.727 15.3488 17.727 14.5455C17.727 13.7421 17.0758 13.0909 16.2725 13.0909H1.72703Z" fill="white"/></svg>
              </div>
              {!props.isLoggedout &&
              <div className=" m:mr-4 l:mr-6 xl:mr-8y mr-10 hidden m:block group p-0 m:scale-75 xl:scale-90 xxl:scale-100">
                <div className="flex text-l cursor-pointer">{choosenLanguage}<img className=" ml-3" src={IconFolderOpenerDarkBlue} alt="" /></div>
                <div className="hidden group-hover:block absolute pt-0 border border-t-0 bg-white text-l">
                    <div onClick={() => handleLocalisation('de')} className="p-5 border-t border-blueDark bg-white hover:bg-blueDark  h-8 flex items-center hover:text-white"><span className="block link">Deutsch</span></div>
                    <div onClick={() => handleLocalisation('en')} className="p-5 border-0 border-t bg-white hover:bg-blueDark border-blueDark h-8 flex items-center hover:text-white"><span className="block link">English</span></div>
                </div>
              </div>
              }

              <div onClick={()=> {setMenuSupport(!menuSupport)}} className=" text-3xl items-center justify-center  bg-white rounded-full w-11 h-11 m:mr-4 l:mr-5 xl:mr-6 xxl:mr-7 hidden m:flex cursor-pointer m:scale-75 xl:scale-90 xxl:scale-100" >?</div>
              {!props.isLoggedout &&
              <svg onClick={()=>logoutAuth0()} className="m:mr-1 l:mr-2 xl:mr-3 xxl:mr-4 cursor-pointer hidden m:block  m:scale-75 xl:scale-90 xxl:scale-100" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="22" cy="22" r="22" fill="white"/>
                <path d="M28 17L26.59 18.41L29.17 21H19V23H29.17L26.59 25.58L28 27L33 22L28 17ZM15 15H23V13H15C13.9 13 13 13.9 13 15V29C13 30.1 13.9 31 15 31H23V29H15V15Z" fill="#102A68"/>
              </svg>}
            </div>
            <div className={menuSupport?  " hidden m:flex Content  justify-center items-center text-white absolute top-0 left-0 w-full h-screen bg-opacity-50 bg-blueLight " : " hidden "}>
              <div className=' w-11/12'>
                <div className=' h-10 flex justify-end bg-blueDark'>
                  <svg className=" w-8 l:w-10 -mt-20 l:-mt-20 -mr-8 l:-mr-10 cursor-pointer" onClick={()=> {setMenuSupport(!menuSupport)}} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22" cy="22" r="22" fill="#102A68"/>
                    <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="white"/>
                  </svg>
                </div>
                <div className='flex items-center flex-col pt-8 pb-8 text-blueDark bg-white'>
                  <h3 className="font-bold">{I18n.get('supportWelcome')}</h3>
                  <div className=' mt-7'>
                    <h3 className="font-bold">{I18n.get('supportSubline')}:</h3>
                    <p className="text-sm m:text-base xxl:text-lg">T: <a href="tel:+49 30 62 93 21 40" className="hover:text-blueDark underline">+49 30 62 93 21 40</a></p>
                    <p className="text-sm m:text-base xxl:text-lg">E: <a href="mailto:support@aufzughelden.com" className="hover:text-blueDark underline">support@aufzughelden.com</a></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      { menuOpen && <Menu isLoggedout={props.isLoggedout} onClick={() => setMenuOpen(false)}/>}
    </section>
  );
}

export default Header;
