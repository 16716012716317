import { useSearchParams } from 'react-router-dom';
import { strings as stringGlobal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { USER_CHOICE_LANGUAGE } from '../utils/consts'
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import { useEffect } from 'react';
import moment from 'moment';
import { useGlobalState } from '../utils/globalStateProvider';

I18n.putVocabularies(stringGlobal);
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
I18n.setLanguage(lang)

export const LogoutPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();// eslint-disable-line @typescript-eslint/no-unused-vars
  const { state, setState } = useGlobalState()// eslint-disable-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);  

  const login = () => {
    const organization = searchParams.get("organization")
    if(organization){
      window.location.href='/org_'+organization+'/welcome'
    }
      else{
        window.location.href='/welcome'
    }
  }

  return (
    <>
      <Header isLoggedout={true} />
      <div className="flex w-full  items-center justify-center flex-col pt-20 pb-20 text-center pl-4 pr-4 h-s min-h-[50vh]">
          <h2>{I18n.get('messageLogout')}</h2>
          <h3 className="pt-5">{I18n.get('messageGoTo')}<span onClick={()=>login() } className=" font-bold cursor-pointer">{I18n.get('login')}</span>.</h3>
      </div>
      <Footer />
    </>
  );
};
