import { Routes, Route, Navigate } from 'react-router-dom'
import Welcome from './pages/Welcome'
import { LoginPage } from './pages/Login'
import { LogoutPage } from './pages/Logout'
import Callback from './pages/Callback'
import { RequireAuth } from './utils/RequireAuth'
import { GlobalStateProvider, useGlobalState } from "./utils/globalStateProvider"
import { useAuth0 } from '@auth0/auth0-react'
import { Spin} from 'antd';
import { I18n } from '@aws-amplify/core'
import {  USER_CHOICE_LANGUAGE} from './utils/consts';
import { useEffect, } from 'react'
import Header from './components/global/Header';
import Footer from './components/global/Footer';
import moment from 'moment'
import { strings as stringGlobal } from './utils/globalLocalization'

I18n.putVocabularies(stringGlobal);
let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
I18n.setLanguage(lang)

export default function App() {

    const { isLoading, error, loginWithRedirect} = useAuth0();
    const { state, setState } = useGlobalState()// eslint-disable-line @typescript-eslint/no-unused-vars

    const loginWithoutOrg = () => {
      loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });  
    }
    
    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        moment.locale(state.language);  
      }
    }, [ state.language]);  

    if (error) {
      let errorMessage = error.message

      if(errorMessage.includes('is not part of the') && errorMessage.includes('organization')){
        // case: user google-apps|robert@digitalspine.io is not part of the org_BdGtxj3VINHioKjk organization
        let messageParts = errorMessage.split(' ')
        let username = messageParts[1]
        errorMessage = I18n.get('auth0ErrorMessageNotPartOfOrg').replace('###userName###',username)
      }
      else{
        errorMessage = I18n.get(error.message)
      }

      return <>
        <Header isLoggedout={true} />
          <div className="flex w-full  items-center justify-center flex-col pt-20 pb-20 text-center pl-4 pr-4 min-h-[50vh]">
              <h2>{I18n.get('errorOccurred')}</h2>
              <h3 className="pt-5">{errorMessage}</h3>
              <h3>{I18n.get('messageGoTo')}<span onClick={()=>loginWithoutOrg() } className=" font-bold cursor-pointer">Login</span>.</h3>
          </div>
        <Footer />
      </>
    }
  
    if (isLoading) {
      return <div className="flex h-screen"><div className="m-auto"><Spin size="large" /></div></div>;
    }

  return (
    <>
      <GlobalStateProvider>
        <Routes>
          <Route path="/" element={<Navigate to={'/welcome'} />} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/logout" element={<LogoutPage/>} />
          <Route element={<RequireAuth />}>
            <Route path='/callback' element={<Callback/>}/>
            <Route path='/welcome' element={<Welcome/>}/>
          </Route>
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
      </GlobalStateProvider>
    </>
  );
}
