import { strings as stringLocal} from './WelcomeLocalization';
import { strings as stringGlobal } from '../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { USER_CHOICE_LANGUAGE } from '../utils/consts';
import React, { useEffect, useState } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import { GlobalStateInterface, useGlobalState } from '../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { Input } from 'antd';
import { organization } from '../interfaces/UsersResponse';
import { getUserInfo } from '../utils/helpersFetch';

const Dashboard = () => {
  const { state, setState } = useGlobalState() 
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [ organizations, setOrganizations] = useState<organization[]>([]);


  useEffect(() => {

    const loadUserInfo = async () => {
      let accessToken =  await getAccessTokenSilently({ignoreCache:true})
      const userInfo = await getUserInfo(accessToken)
      setOrganizations(userInfo.organizations)
    }
    loadUserInfo()
  }, [getAccessTokenSilently]);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }, [setState,state.language]);

  const getButton = (organization:organization) => {
    return <div key={organization.display_name} title={organization.display_name} className="button disabled mr-5 mb-5 ellipsis w-full s:w-[15rem] text-center"><span className=" pl-3 pr-3">{organization.display_name}</span></div>
  }

  const getButtons = () => {
    
    let arrOrganisations:any[] = []
    organizations?.forEach((organization)=>{
      arrOrganisations.push(getButton(organization))
    })

    return arrOrganisations
  }

  return (
    <div className={isAuthenticated ? '' : 'hidden'}>
    <Header />
    <section className="Content">
      <div className="pt-10 pb-20">
        <h1>{I18n.get('myAccount')}</h1>
        <div className="mt-5">{I18n.get('aboutYou')}</div>
        <div className="mt-3">{I18n.get('fullName')}</div>
        <div className=" s:w-6/12 m:w-3/12"><Input placeholder={user?.name} disabled={true} /></div>
        <div className="mt-3">{I18n.get('eMail')}</div>
        <div className=" s:w-6/12 m:w-3/12"><Input placeholder={user?.email} disabled={true} /></div>
        <div className="mt-5">{I18n.get('organisations')}</div>
        <div className=" mt-3">{getButtons()}</div>
      </div>
    </section>
    <Footer />
    </div>
  );
};

export default Dashboard;
