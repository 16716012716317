import { strings as stringGlobal } from '../../utils/globalLocalization';
import { strings as stringFooter } from './FooterLocalization';
import { I18n } from '@aws-amplify/core';
import { USER_CHOICE_LANGUAGE } from '../../utils/consts';
import { mergeDeepRight } from 'ramda';

I18n.putVocabularies(mergeDeepRight(stringFooter,stringGlobal));
I18n.setLanguage(localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de')

function Footer() {
  return (
    <section className="Footer bg-blueLight text-white">
      <div className=" pt-10 xxl:pt-14 pb-10 xxl:pb-20 flex flex-wrap">
        <div className="hidden m:block m:w-1/12"></div>
        <div className=" w-full m:w-10/12 flex flex-col-reverse m:flex-row ">
          <div className=" m:pt-36 m:w-7/12 m:pl-6">
            <h2 className="text-white mb-6 pt-20 m:pt-0">{I18n.get('followUs')}</h2>
            <div className="flex flex-grow self-end pb-3">
              <a href="https://www.instagram.com/digital_spine/" target="_blank" rel="noreferrer">
                <svg className="h-5 pr-4" fill="#102A68" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.063 169.063">
                  <g>
                    <path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
                      c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
                      c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
                      c17.455,0,31.656,14.201,31.656,31.655V122.407z"/>
                    <path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
                      C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
                      c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/>
                    <path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
                      c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
                      C135.661,29.421,132.821,28.251,129.921,28.251z"/>
                  </g>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/67966143" target="_blank" rel="noreferrer">
                <svg viewBox="0 0 24 24" version="1.1" className="h-5" fill="#102A68">
                  <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
                      <g id="Follow-us" transform="translate(-212.000000, -208.000000)" >
                          <g transform="translate(140.000000, 199.000000)">
                              <g id="Group" transform="translate(62.000000, 0.000000)" fillRule="nonzero">
                                  <g id="linkedin" transform="translate(-0.000000, 0.000000)">
                                      <path d="M34,33 L29.0323144,33 L29.0323144,25.2050385 C29.0323144,23.3449965 28.9945852,20.9538139 26.4457642,20.9538139 C23.8571179,20.9538139 23.4609607,22.9776067 23.4609607,25.0685794 L23.4609607,33 L18.4932751,33 L18.4932751,16.9713086 L23.2639301,16.9713086 L23.2639301,19.1546536 L23.3268122,19.1546536 C23.9933624,17.8950315 25.6157205,16.5661302 28.0366812,16.5661302 C33.0672489,16.5661302 34,19.8831351 34,24.2015395 L34,32.9937019 L34,33 Z M12.8841921,14.7774668 C11.2869869,14.7774668 10,13.4821554 10,11.886634 C10,10.2953114 11.2869869,9 12.8841921,9 C14.4772052,9 15.7683843,10.2953114 15.7683843,11.886634 C15.7683843,13.4821554 14.4751092,14.7774668 12.8841921,14.7774668 Z M15.3743231,33 L10.3940611,33 L10.3940611,16.9713086 L15.3743231,16.9713086 L15.3743231,33 L15.3743231,33 Z" id="Shape"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className=" m:w-5/12 m:pl-14">
            <h2 className=" text-blueDark m:text-white">{I18n.get('contact')}</h2>
            <p className=" text-base xxl:text-xl pt-3 xxl:pt-6">+49 (0) 30 629 321 38<br/>info@aufzughelden.com</p>
          </div>
          <div className="hidden m:block m:col-span-2"></div>
        </div>
        <div className="hidden m:block  m:w-1/12 "></div>
        <div className="hidden m:block w-1/12 "></div>
        <div className=" w-full m:w-10/12 ">
          <div className="flex flex-col-reverse m:flex-row m:flex text-base">
            <div className="m:w-7/12 m:pl-6 pt-6 m:pt-0 ">{I18n.get('footerText')}</div>
            <div className="flex flex-col m:block m:w-5/12 m:pl-14">
              <a className="text-white mr-10 hover:text-white cursor-pointer" href={I18n.get('imprintLink')} target="_blank" rel="noreferrer">{I18n.get('imprint')}</a>
              <a className="text-white pt-4 m:pt-0 hover:text-white cursor-pointer" href={I18n.get('dataProtectionLink')} target="_blank" rel="noreferrer">{I18n.get('dataProtection')}</a>
            </div>
          </div>
        </div>
        <div className="hidden m:block  w-1/12"></div>
      
      </div>
    </section>
  );
}

export default Footer;
