import 'antd/dist/antd.min.css';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import React from 'react';
import { auth0_domain,auth0_clientId,auth0_audience } from './utils/consts';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const onRedirectCallback = (appState: any) => {
  let navTO = appState && appState.returnTo ? appState.returnTo : window.location.pathname
  window.location.href=navTO
};

function RenderApp(providerConfig:any){
  root.render(
    /*<React.StrictMode>*/
      <BrowserRouter>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </BrowserRouter>
    /*</React.StrictMode>*/
  );
}

const  LoadOrganizationIdBeforeReactRenders = async() =>{

  let providerConfig = {
    domain: auth0_domain,
    clientId: auth0_clientId,
    ...(auth0_audience? { audience: auth0_audience } : null ),
    redirectUri: window.location.origin+'/callback',
    //allowSignUp:true,
    ignoreCache:true,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    onRedirectCallback,
  };

  RenderApp(providerConfig)
}

LoadOrganizationIdBeforeReactRenders()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
