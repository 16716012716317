import { Outlet } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const RequireAuth = () => {

  const { user,loginWithRedirect } = useAuth0();

  if (!user) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    })
  }
  
  return <Outlet />;
};
